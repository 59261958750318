import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

const Button = ({ id, text, icon, type, onClick, isIconFirst = false, isLoading = false }) => (
  <button
    id={id}
    onClick={() => onClick && onClick()}
    type='button'
    className={`btn btn--${type} ${icon ? 'btn--with-icon' : ''} ${isIconFirst ? 'btn--reversed' : ''} ${
      isLoading ? 'loading' : ''
    }`}
  >
    <span className='btn__text'>{text}</span>
    {icon && <FontAwesomeIcon icon={isLoading ? faCircleNotch : icon} />}
  </button>
);

export default Button;
