const isClient = typeof window === 'object';
const getHtml = () => {
  if (!isClient) return;
  return document.querySelector('html');
};

export const lockScroll = () => {
  const html = getHtml();
  if (!html) return;

  html.style.overflow = 'hidden';
};

export const unlockScroll = () => {
  const html = getHtml();
  if (!html) return;

  html.style.overflow = 'visible';
};
