import React, {useState, useRef, useEffect, useContext} from 'react';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import useContent from '../../content/useContent';
import Image from '../Image/Image';
import Button from '../Button/Button';
import { lockScroll, unlockScroll } from '../../utils/togglePageScroll';
import FreeRaportModal from './FreeRaportModal';
import {useLocation, useNavigate} from "@reach/router";
import {Context} from "../../store/context";

const FreeRaport = () => {
  const context = useContext(Context);
  const content = useContent('freeRaport');
  if (!content || !context) return null;

  const location = useLocation();
  const navigate = useNavigate();
  const [state, dispatch] = context;

  const modalWrapperRef = useRef(null);
  const { imgUrl, title1, title2, essence, btnText } = content;

  const [isRaportModalVisible, setIsRaportModalVisible] = useState(false);
  const [isInitial, setIsInitial] = useState(true)

  const openUsageModal = () => {
    setIsRaportModalVisible(true);
    lockScroll();
  };

  const closeUsageModal = () => {
    setIsRaportModalVisible(false);
    unlockScroll();

    if (location.hash === '#raport') {
      navigate(location.origin);
    }
  };


  useEffect(() => {
    if (isInitial) {
      setIsInitial(false);
      return;
    }

    if (location.hash !== '#raport') return;

    openUsageModal();
    return () => {
      closeUsageModal();
    }
  }, [location, state])

  return (
    <>
      <section className='free-raport section section--dark'>
        <div className='free-raport__inside boxed'>
          <div className='free-raport__img-wrapper'>
            <Image filename={imgUrl} />
          </div>
          <div className='free-raport__content'>
            <h3 className='f2'>{title1}</h3>
            <h2>{title2}</h2>
            <p>{essence}</p>
            <Button id='btnOpenRaportModal' onClick={openUsageModal} text={btnText} icon={faCaretRight} type={1} />
          </div>
        </div>
      </section>

      <FreeRaportModal
        onClose={closeUsageModal}
        forwardedRef={modalWrapperRef}
        modalVisibility={isRaportModalVisible}
      />
    </>
  );
};

export default FreeRaport;
