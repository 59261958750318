import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import useContent from '../../content/useContent';
import api from '../../utils/api';
import Image from '../Image/Image';

const TrustedUs = () => {
  const content = useContent('trustedUs');
  const { title, logoUrls } = content;

  const [logos, setLogos] = useState(null);

  const getLogos = async () => {
    const response = await api.get('get-logos');

    if (response && response.data) {
      setLogos(response.data.logos);
    }
  };

  useEffect(() => {
    getLogos();
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 6,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className='trusted-us'>
      <h3 className='f4 text-center'>{title}</h3>

      <Slider {...settings}>
        {logos && logos.map((url) => <img key={url} src={url} className='truested-us__slide' />)}
      </Slider>
    </div>
  );
};

export default TrustedUs;
