import React, {useState, useEffect, useRef} from 'react';
import { faEnvelope, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import useContent from '../../content/useContent';
import Image from '../Image/Image';
import TrustedUs from '../TrustedUs/TrustedUs';
import Button from '../Button/Button';
import api from '../../utils/api';
import ReCAPTCHA from "react-google-recaptcha";

const Contact = () => {
  const content = useContent('contact');
  const captchaRef = useRef(null);
  const { title, demo, consultant, form } = content;
  const { demoImg, demoTitle, demoText } = demo;
  const {
    consultantImg,
    consultantName,
    consultantProfession,
    consultantTel,
    consultantEmail,
    consultantDetail,
  } = consultant;
  const { formSubmitBtnText, formFields, failMassage, successMassage, errorMessage } = form;

  const company = useContent('company');
  const { companyName, companyAddress } = company;

  const initialFormData = {
    name: '',
    company: '',
    phone: '',
    mail: '',
    message: '',
  };

  const [formData, setFormData] = useState(initialFormData);

  const [isSent, setIsSent] = useState(false);
  const [wrongFields, setWrongFields] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [token, setToken] = useState(null);
  const [apiError, setApiError] = useState(null);

  const clearMessages = () => {
    setIsSent(false);
    setWrongFields(null);
    setApiError(null);
    if (captchaRef.current) {
      captchaRef.current.reset();
      setToken(null);
    }
  };

  const [isContactHidden, setIsContactHidden] = useState(false);

  const sendMessage = async () => {
    clearMessages();

    if (!token) {
      setWrongFields(['captcha']);
      return;
    }

    setIsLoading(true);

    const response = await api.post('send-message', {
      name: formData.name,
      company: formData.company,
      phone: formData.phone,
      mail: formData.mail,
      message: formData.message,
      token,
    });

    if (response.status !== 200) {
      setIsLoading(false);
      setApiError(errorMessage);
      return;
    }

    const { data } = response;

    if (data.status) {
      window.dataLayer.push({ event: 'contact-form-send' });
      setIsSent(true);
      setFormData(initialFormData);
    }

    if (data.error) {
      setWrongFields(Object.keys(data.error));
    } else {
      setWrongFields(null);
    }

    if (data) {
      setIsLoading(false);
    }
  };

  const updateFormData = (value, key) => {
    setFormData({ ...formData, [key]: value });
  };

  const getErrorName = (error) => formFields.find((field) => field.fieldKey === error).fieldLabel;
  const getClassWrongInput = (currentFieldKey) => {
    if (!wrongFields) return;

    return wrongFields.includes(currentFieldKey) ? 'wrong' : '';
  };

  const showContact = () => {
    window.dataLayer.push({
      event: 'showContact',
    });

    setIsContactHidden(true);
  };

  const deleteWhitespace = (string) => string.replace(/\s+/g, '');

  const handleCaptchaChange = (value) => {
    setToken(value);
  }

  return (
    <section id='contact' className='contact section section--light'>
      <div className='contact__inside boxed'>
        <div className='contact__title-wrapper'>
          <h2 className='contact__title text-center'>{title}</h2>
        </div>
        <div className='contact__content col-1-1'>
          <div className='contact__text'>
            <div className='contact__demo'>
              <Image filename={demoImg} />
              <div>
                <h5>{demoTitle}</h5>
                <p className='f3 bold'>{demoText}</p>
              </div>
            </div>
            <div className='contact__consultant consultant'>
              <div className='consultant__intro'>
                <Image className='consultant__img' filename={consultantImg} />
                <div className='consultant__text'>
                  <p className='f3 bold'>{consultantName}</p>
                  <p className='f4'>{consultantProfession}</p>
                </div>
              </div>
              <div className={`consultant__contact ${isContactHidden ? 'isHidden' : ''}`}>
                <div className='curtain'>
                  <Button type={3} text={consultantDetail} icon={faCaretRight} onClick={showContact} />
                </div>
                <a href={`tel:${deleteWhitespace(consultantTel)}`}>{consultantTel}</a>
                <a href={`mailto:${consultantEmail}`}>{consultantEmail}</a>
              </div>
              <div>
                <p className='f4 bold'>
                  {companyName}
                  <br />
                  {companyAddress}
                </p>
              </div>
            </div>
          </div>
          <form className='contact__form form'>
            <div className='form__content'>
              {formFields.map(({ fieldType, fieldLabel, isRequired, fieldKey }) => (
                <div className={`form__field ${fieldType === 'textarea' ? 'textarea' : 'ordinary'}`} key={fieldLabel}>
                  {fieldType !== 'captcha' && (
                      <label className={`${isRequired ? 'isRequired' : ''}`}>{fieldLabel}</label>
                  )}

                  {fieldType === 'textarea' && (
                    <textarea
                      value={formData[fieldKey]}
                      className={getClassWrongInput(fieldKey)}
                      onChange={(e) => updateFormData(e.target.value, fieldKey)}
                    />
                  )}

                  {fieldType === 'text' && (
                    <input
                      value={formData[fieldKey]}
                      className={getClassWrongInput(fieldKey)}
                      type={fieldType}
                      onChange={(e) => updateFormData(e.target.value, fieldKey)}
                    />
                  )}

                  {fieldType === 'captcha' && (
                      <div className={`form__captcha-wrapper ${getClassWrongInput(fieldKey)}`}>
                        <ReCAPTCHA
                            sitekey='6LckVCQaAAAAACMCtzM5On4qxOkwEoErk4o3zBRw'
                            onChange={handleCaptchaChange}
                            ref={captchaRef}
                        />
                      </div>
                  )}
                </div>
              ))}
            </div>
            {(wrongFields || apiError) && (
              <div className='form__error'>
                {apiError ? (
                    <p>{apiError}</p>
                ) : (
                    <p>
                      {failMassage}
                      {wrongFields.map((error, index) => (
                          <span key={index}>
                      {index === wrongFields.length - 1 ? getErrorName(error) : `${getErrorName(error)}, `}
                    </span>
                      ))}
                    </p>
                )}
              </div>
            )}
            {isSent && <div className='form__success'>{successMassage}</div>}
            <div className='form__btn-wrapper'>
              <Button
                id='btnSendMessage'
                text={formSubmitBtnText}
                icon={faEnvelope}
                type={1}
                isIconFirst
                onClick={sendMessage}
                isLoading={isLoading}
              />
            </div>
          </form>
        </div>
        <TrustedUs />
      </div>
    </section>
  );
};

export default Contact;
