import React, { useRef, useEffect, useState } from 'react';
import { faTimes, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnimatePresence, motion } from 'framer-motion';
import useContent from '../../content/useContent';
import useOutsideClick from '../../hooks/useOutsideClick';
import Image from '../Image/Image';
import Button from '../Button/Button';
import api from '../../utils/api';

const FreeRaportModal = ({ forwardedRef, modalVisibility, onClose }) => {
  const modalRef = useRef(null);

  const content = useContent('freeRaport');
  const { imgUrl, title1, title2, modal } = content;
  const { modalText, modalForm, failMassage, successMassage } = modal;
  const { formSubmitText, formFields, modalRight } = modalForm;
  const [isLoading, setIsLoading] = useState(false);

  const [isRightAccepted, setIsRightAccepted] = useState(false);

  useOutsideClick(modalRef, onClose, modalVisibility);

  const initialFormData = {
    name: '',
    company: '',
    phone: '',
    mail: '',
  };
  const [formData, setFormData] = useState(initialFormData);

  const [isSent, setIsSent] = useState(false);
  const [wrongFields, setWrongFields] = useState(null);

  const clearMessages = () => {
    setIsSent(false);
    setWrongFields(null);
  };

  const sendMessage = async () => {
    setIsLoading(true);
    clearMessages();

    const response = await api.post('free-raport', {
      name: formData.name,
      company: formData.company,
      phone: formData.phone,
      mail: formData.mail,
      message: formData.message,
      isRightAccepted: isRightAccepted ? true : null,
    });

    const { data } = response;

    if (data.status) {
      window.dataLayer.push({
        event: 'downloadReport',
      });

      setIsSent(true);
      setFormData(initialFormData);
      setIsRightAccepted(false);
    }

    if (data.error) {
      setWrongFields(Object.keys(data.error));
    } else {
      setWrongFields(null);
    }

    setIsLoading(false);
  };

  const updateFormData = (value, key) => {
    setFormData({ ...formData, [key]: value });
  };

  const getErrorName = (error) => formFields.find((field) => field.fieldKey === error).fieldLabel;
  const getClassWrongInput = (currentFieldKey) => {
    if (!wrongFields) return;

    return wrongFields.includes(currentFieldKey) ? 'wrong' : '';
  };

  return (
    <div className='modal-wrapper'>
      <AnimatePresence>
        {modalVisibility && (
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <div className='raport-modal modal' ref={forwardedRef}>
              <div className='modal__inside' ref={modalRef}>
                <button type='button' className='modal__exit' onClick={onClose}>
                  <FontAwesomeIcon icon={faTimes} />
                </button>
                <div className='modal__head'>
                  <Image className='modal__img' filename={imgUrl} />
                </div>
                <div className='modal__content'>
                  <h4 className='f3 text-center'>{title1}</h4>
                  <h2 className='text-center'>{title2}</h2>
                  <p className='modal__essence text-center'>{modalText}</p>

                  <form className='raport-form contact__form form'>
                    <div className='form__content'>
                      {formFields.map(({ fieldType, fieldLabel, isRequired, fieldKey, fieldText }) => (
                        <div key={fieldKey} className={`${fieldType === 'right' ? 'form__right' : 'item'}`}>
                          {fieldType === 'right' ? (
                            <label className={`form__right ${getClassWrongInput(fieldKey)}`}>
                              <input
                                className='form__checkbox'
                                type='checkbox'
                                checked={isRightAccepted}
                                value={formData[fieldKey]}
                                onChange={(e) => setIsRightAccepted(e.target.checked)}
                              />
                              {fieldText}
                            </label>
                          ) : (
                            <div className={`form__field ${fieldType === 'textarea' ? 'textarea' : 'ordinary'}`}>
                              <label className={`${isRequired ? 'isRequired' : ''}`}>{fieldLabel}</label>
                              <input
                                value={formData[fieldKey]}
                                className={getClassWrongInput(fieldKey)}
                                type={fieldType}
                                onChange={(e) => updateFormData(e.target.value, fieldKey)}
                              />
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                    {wrongFields && (
                      <div className='form__error'>
                        <p>
                          {failMassage}
                          {wrongFields.map((error, index) => (
                            <span key={index}>
                              {index === wrongFields.length - 1 ? getErrorName(error) : `${getErrorName(error)}, `}
                            </span>
                          ))}
                        </p>
                      </div>
                    )}
                    {isSent && <div className='form__success'>{successMassage}</div>}
                    <div className='form__btn-wrapper'>
                      <Button
                        id='btnGetFreeRaport'
                        text={formSubmitText}
                        icon={faEnvelope}
                        type={1}
                        isIconFirst
                        onClick={sendMessage}
                        isLoading={isLoading}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default FreeRaportModal;
